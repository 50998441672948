@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@200;300;400;500;600;700;800&display=swap');

* {
  font-family: 'Manrope', sans-serif;
}

.main-area {
  background: #F9F9F9;
}

.container {
  padding: 0 185px;
  max-width: 100%;
}

.dmt_test .dmv__header {
  background: transparent;
  position: fixed;
  width: 100%;
  left: 0;
  top: 0;
  z-index: 99;
}


.dmv__header {
  padding: 18px 0;
}

.dmv_logo {
  background: #F9F9F9;
  height: 100%;
}

.item_flex {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.dmv_logo a img {
  margin: 5px 0 0 0;
}

.dmv_logo,
.s_button {
  padding: 18px 0 20px;
}


.dmv_button a {
  font-size: 14px;
  line-height: 20px;
  color: #504F54;
  border: 1px solid transparent;
  font-weight: 500;
  background: transparent;
  margin-left: 30px;
  display: inline-block;
  text-decoration: none;
  padding: 0 4px;
}


.dmv_button a:nth-child(3) {
  border: 2px solid rgb(96, 40, 195);
  padding: 8px 33px;
  border-radius: 4px;
  background: #F9F9F9;
}

.hr_h {
  height: 1px;
  background: #EBEAED !important;
  opacity: 1 !important;
  margin: 20px 0;
}

/*===bg-area===*/


.join_btn {
  margin: 0 0 14px;
}

.join_btn button {
  color: #FFFFFF;
  font-size: 14px;
  line-height: 20px;
  font-weight: 700;
  background-color: #613EB7;
  padding: 8px 26.4px;
  border-radius: 4px;
  border: none;
}

.bg-area {
  padding: 0 0 170px;
  min-height: 100vh;
}

.bac__bg {
  background: url('../../../static/images_frontend/bg.png') no-repeat;
  background-size: cover;
  padding: 24px;
  margin: 0 0 15px;
  position: relative;
}

.bac__bg img {
  position: absolute;
  right: -2%;
  top: 50%;
  transform: translate(-50%,-50%);
}

.bac__bg h2 {
  font-size: 36px;
  line-height: 42px;
  color: #ffffff;
  font-weight: 600;
  margin: 0 0 12px;
}

.bac__bg p {
  font-size: 14px;
  line-height: 20px;
  color: #ffffff;
  font-weight: 500;
}

.quiz__area {
  padding: 15px;
  background: #FFFFFF;
  margin-bottom: 10px;
  border-radius: 8px;
}

.quiz_heading h2 {
  font-size: 36px;
  line-height: 48px;
  margin: 0;
  color: #613EB7;
}

.quiz_heading h2 span {
  color: #2E2C34;
  margin-left: 10px;
  display: inline-block;
}

.quiz_p {
  height: 100%;
  display: flex;
  align-items: flex-start;
  padding-top: 14px;
}

.quiz_p p {
  font-size: 14px;
  line-height: 20px;
  margin: 0;
}

.quiz_link a {
  display: inline-block;
  width: 48%;
  font-size: 14px;
  line-height: 20px;
  text-decoration: none;
  padding: 13px 0px;
  border-radius: 4px;
  border: 1px solid #613eb7;
  text-align: center;
  margin-left: 10px;
  color: #613eb7;
}

.quiz_link a:first-child {
  margin-left: 0px;
}

.quiz_link a:last-child {
  color: #fbfafc;
  background-color: #613eb7;
}

.result-btns {
  display: flex;
  gap: 15px;
}

.result-btns a {
  width: 33.33%;
  margin: 0;
}

.result {
  border: none !important;
  background: #F3F3F3;
  color: #000000 !important;
}

.popup_content2 {
  padding: 90px 0 50px;
  position: relative;
}

.bg-area .modal-content {
  border-radius: 0;
}

.popup_content2 button {
  width: 36px;
  height: 36px;
  background: #F3F3F3;
  border-radius: 50%;
  color: #7A7A7A;
  position: absolute;
  right: 25px;
  top: 20px;
}

.popup_content2 button i {
  font-weight: 300 !important;
  font-size: 30px;
  color: #7A7A7A !important;
}

.popup_content2 button:focus {
  box-shadow: none;
  background: #F3F3F3 !important;
}

.popup_content2 form {
  margin: 60px 0 0;
}

.popup_content2 form input:first-child {
  font-size: 17px;
  line-height: 22px;
  color: #3C3C43;
  padding: 12px 16px;
  border: 1px solid #f3f3f3;
  max-width: 310px;
}

.popup_content2 form input:first-child:focus {
  outline: none;
}

.popup_content2 form input:last-of-type {
  font-size: 14px;
  line-height: 20px;
  padding: 16px 43px;
  border-radius: 4px;
  background-color: #f3f3f3;
  border: none;
  margin-left: 10px;
  color: #B8B8B8;
}


/*===signin.html===*/

.around__area .container {
  max-width: 100%;
  padding: 0;
}

.left__side {
  padding: 243px 0 230px;
}

.aside__part {
  background: #FFFFFF;
}

.aside__part {
  padding: 243px 0 230px;
}

.p_arg {
  padding: 18px 0 0;
}

/*.s_button {*/
/*  background: #FFFFFF;*/
/*  !*! padding: 18px 0 0; *!*/
/*}*/

.around_im {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding-bottom: 80px;
  background: #F9F9F9;
}

.sign_form {
  max-width: 385px;
  margin: 0 auto;
}

.sign_form h2 {
  font-size: 36px;
  line-height: 48px;
  color: #2e2c34;
  font-weight: 600;
  margin: 0 0 13px;
}

.new_p {
  font-size: 14px;
  line-height: 20px;
  color: #2e2c34;
  font-weight: 500;
  margin: 0 0 12px;
}

.new_p_font {
  font-size: 14px;
  line-height: 20px;
  color: #2e2c34;
  font-weight: 500;
}

.new_p a {
  display: inline-block;
  text-decoration: none;
  color: #613eb7;
  margin-left: 8px;
}

.forget_a {
  margin: 30px 0 0;
}

.sign_form [type="submit"] {
  font-size: 14px;
  line-height: 20px;
  color: #fbfafc;
  font-weight: 600;
  border-radius: 4px;
  background-color: #613eb7;
  display: block;
  padding: 14px 0;
  width: 100%;
  border: none;
  margin: 25px 0 0;
}

.hz {
  height: 1px;
  background: #EBEAED !important;
  opacity: 1 !important;
  margin: 20px 0 !important;
}

.anything {
  position: relative;
  margin: 37px 0 0;
}

.anything p {
  font-size: 14px;
  line-height: 20px;
  color: #2e2c34;
  font-weight: 500;
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  padding: 0 8px;
  background: #fff;
}

.google_btn {
  display: block;
  width: 100%;
  text-align: center;
  background: transparent;
  border: 1px solid #ebeaed;
  border-radius: 4px;
  padding: 16px;
  margin: 41px 0 0;
}

.google_btn img {
  margin-right: 8px;
}



/*==animation-placeholder===*/

.form-group,
.form-group1{
  position: relative;
}
.form-group + .form-group,
.form-group1 + .form-group1 {
  margin-top: 30px;
}

.form-label {
  position: absolute;
  left: 0;
  top: 10px;
  /*background-color: #fff;*/
  z-index: 10;
  transition: transform 150ms ease-out, font-size 150ms ease-out;
  font-size: 14px;
  line-height: 20px;
  color: #84818a;
  font-weight: 500;
}

.focused .form-label {
  transform: translateY(-125%);
  font-size: 0.75em;
}

.form-input {
  position: relative;
  padding: 12px 0px 5px 0;
  width: 100%;
  outline: 0;
  border: 0;
  box-shadow: 0 1.5px 0 0 #EFEFF1;
  transition: box-shadow 150ms ease-out;
  border-radius: 0;
}
.form-input:focus {
  box-shadow: 0 2px 0 0 #7A6BF8;
  border-radius: 0;
}

.form-input.filled {
  box-shadow: 0 2px 0 0 lightgreen;
  border-radius: 0;
}


/*==password==*/

.field-icon {
  float: right;
  margin-left: -25px;
  margin-top: -25px;
  position: relative;
  z-index: 2;
}

.fa-eye::before {
  content: "\f06e";
}

.fa-eye-slash::before {
  content: "\f070";
}

/*.fa-eye-slash::before {
  content: "\f070";
}

.fa-eye::after {
  content: "\f06e";
}*/


/*===checkbox===*/

.form input[type=checkbox] {
  position: relative;
  border: 2px solid #84818A;
  border-radius: 2px;
  background: none;
  cursor: pointer;
  line-height: 0;
  margin: 0 .6em 0 0;
  outline: 0;
  padding: 0 !important;
  vertical-align: text-top;
  height: 18px;
  width: 18px;
  -webkit-appearance: none;
  /*! opacity: .5; */
  margin-right: 16px;
}

.form input[type=checkbox]:checked {
  background-color: #84818A;
  opacity: 1;
  margin-right: 16px;
}

.form input[type=checkbox]:before {
  content: '';
  position: absolute;
  right: 50%;
  top: 50%;
  width: 6px;
  height: 11px;
  border: solid #FFF;
  border-width: 0 2px 2px 0;
  margin: -1px -1px 0 -1px;
  transform: rotate(45deg) translate(-50%, -50%);
  z-index: 2;
}

.ck_int {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.check_bx {
  font-size: 12px;
  line-height: 18px;
  color: #84818a;
  font-weight: 500;
}

.check_bx span {
  color: #613EB7;
}



/*==create_account==*/

.left__side {
  max-width: 402px;
  margin: 0 auto;
  padding: 237px 0 230px;
}

.review__content {
  padding: 15px 15px 27px;
  background: #fff;
  margin: 0 0 10px;
}

.left__side > h2 {
  font-size: 36px;
  line-height: 48px;
  color: #2e2c34;
  font-weight: 600;
  margin: 0 0 51px;
}

.circle_im {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.jessi_text h3 {
  font-size: 18px;
  line-height: 25px;
  color: #2e2c34;
  font-weight: 400;
  margin: 0 0 10px;
}

.star_icon ul li i {
  color: #007AFF;
}

.dis_color {
  color: #E4E4E6 !important;
}

.start_image div img {
  margin-right: 12px;
}

.status_ac h4 {
  font-size: 17px;
  letter-spacing: 0px;
  line-height: 22px;
  color: #3C3C43;
  font-weight: 400;
}

.recipe {
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
  color: #84818A;
  margin: 19px 0 0;
}



/*fr_group*/

.fr_group {
  width: 49%;
  margin-top:  20px !important;
  margin-bottom: 20px !important;
}


/*star*/


#full-stars-example .rating-group {
  display: inline-flex;
}

#full-stars-example .rating__icon {
  pointer-events: none;
}

#full-stars-example .rating__input {
  position: absolute !important;
  left: -9999px !important;
}

#full-stars-example .rating__label {
  cursor: pointer;
  padding: 0 0.1em;
  font-size: 27px;
  margin: -3px 0 0 0;
}

#full-stars-example .rating__icon--star {
  color: #007AFF;
}

#full-stars-example .rating__icon--none {
  color: #eee;
}

#full-stars-example .rating__input--none:checked + .rating__label .rating__icon--none {
  color: red;
}

#full-stars-example .rating__input:checked ~ .rating__label .rating__icon--star {
  color: #ddd;
}

#full-stars-example .rating-group:hover .rating__label .rating__icon--star {
  color: #007AFF;
}

#full-stars-example .rating__input:hover ~ .rating__label .rating__icon--star {
  color: #ddd;
}

#full-stars-example .rating-group:hover .rating__input--none:not(:hover) + .rating__label .rating__icon--none {
  color: #eee;
}

#full-stars-example .rating__input--none:hover + .rating__label .rating__icon--none {
  color: red;
}

.fa-star::before {
  content: "\e28b";
}






/*==join_quiz==*/

.main__center {
  padding: 185px 0 254px;
}

.center__area {
  max-width: 729px;
  background: #fff;
  padding: 43px 0 33px;
  margin: 0 auto;
}

.se_arw {
  margin-left: 30px;
  display: inline-block;
}

.se_arw span {
  display: inline-block;
  font-size: 14px;
  line-height: 22px;
  color: #2E2C34;
  margin: 0 10px 0 13px;
}

.center__area .field-icon {
  position: absolute;
  z-index: 2;
  bottom: 4%;
  right: 1%;
  transform: translate(-50%,-50%);
}

.join_quiz h2 {
  font-size: 36px;
  line-height: 48px;
  color: #2E2C34;
  margin: 0 0 25px;
}

.join_quiz p {
  font-size: 14px;
  line-height: 20px;
  color: #84818A;
  margin: 0 0 26px;
}

.join_quiz {
  max-width: 358px;
  margin: 0 auto;
}

.join_quiz form label {
  display: block;
}

.join_quiz form input {
  border-radius: 4px;
  background-color: #ffffff;
  border: 1px solid #ebeaed;
  margin: 0 0 24px;
}

.join_quiz .form-control:focus {
  box-shadow: none;
  border: 1px solid #ebeaed;
  outline: none;
}

.join_quiz form .form-group label {
  position: relative;
}

.join_quiz form label span {
  font-size: 12px;
  line-height: 18px;
  color: #84818A;
  display: inline-block;
  margin: 0 0 11px;
}

.tel_item {
  width: 49%;
}

.join_quiz form [type="submit"] {
  font-size: 14px;
  line-height: 20px;
  color: #FBFAFC;
  padding: 20px 68px 17px;
  border-radius: 4px;
  background-color: #613eb7;
  border: none;
}






/*===result_detail===*/

/* Todo: I might undo this but this seems like extra space for no real reason. */
/*.result__details {*/
/*  padding: 110px 0 122px;*/
/*}*/

.result__details .col-lg-4 {
  padding: 8px;
}

.result_content {
  margin: .7rem
}

.result_content h2 {
  font-size: 36px;
  line-height: 48px;
  color: #2E2C34;
  margin: 0 0 20px;
}

.result_content p {
  font-size: 14px;
  line-height: 20px;
  color: #84818A;
}

.quistion_area {
  padding: 33px 25px 28px 32px;
  background: #fff;
  border-radius: 12px;
  margin-bottom: 1rem;
  /*height: 330px;*/
}

.quistion_area h3 {
  font-size: 20px;
  line-height: 28px;
  color: #2E2C34;
  margin: 0 0 -33px;
}

.building_im {
  display: flex;
  align-items: flex-end;
  height: 100%;
  /*justify-content: flex-end;*/
}




/*radio*/

.enquery_text {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #000B2C;
  margin: 33px 0 -49px;
}

.quistion_area ul{
  list-style: none;
  margin: 0;
  overflow: auto;
  display: block;
  padding: 0 0 17px 14px;
  overflow: hidden;
}

.quistion_area ul li{
  color: #AAAAAA;
  display: block;
  position: relative;
  float: left;
  width: 100%;
  /*height: 74px;*/
  /*! border-bottom: 1px solid #333; */
}

.quistion_area ul li input[type=radio]{
  position: absolute;
  visibility: hidden;
}

.quistion_area ul li label{
  display: block;
  position: relative;
  font-size: 1rem;
  padding: 19px 0 0 32px;
  margin: 10px auto;
  z-index: 9;
  cursor: pointer;
  -webkit-transition: all 0.25s linear;
  font-weight: normal;
  color: #84818A !important;
  font-family: 'Manrope', sans-serif;
}


.quistion_area ul li .check{
  display: block;
  position: absolute;
  border: 5px solid #AAAAAA;
  border-radius: 100%;
  height: 24px;
  width: 24px;
  top: 30px;
  left: 0;
  z-index: 5;
  transition: border .25s linear;
  -webkit-transition: border .25s linear;
}

.quistion_area ul li .check::before {
  display: block;
  position: absolute;
  content: '';
  border-radius: 50%;
  height: 11px;
  width: 11px;
  top: 50%;
  left: 50%;
  margin: auto;
  transition: background 0.25s linear;
  -webkit-transition: background 0.25s linear;
  transform: translate(-50%,-50%);
}

.quistion_area input[type=radio]:checked ~ .check {
  border: 3px solid #48BF53 !important;
}


.ck_dander {
  border: 3px solid #F20000 !important;
}

.quistion_area input[type=radio]:checked ~ .check::before {
  background: #48BF53;
}

.ck_dander:before {
  background: #F20000;
}

.quistion_area input[type=radio]:checked ~ label{
  color: #0DFF92;
}

/*pagination*/

.pagination {
  display: inline-block;
  margin: 36px 0 0;
}

.pagination a {
  color: black;
  float: left;
  padding: 8px 16px;
  text-decoration: none;
  font-size: 12px;
  line-height: 18px;
  color: #84818A;
  transition: 0.3s all ease;
}

.pagination a.active_aa {
  background-color: #E8E6F9;
  color: white;
  border-radius: 5px;
  color: #5542F6;
}

.pagination a:hover:not(.active_aa) {
  background-color: #ddd;
  border-radius: 5px;
}



.modal-footer {
  justify-content: inherit !important;
}


/*==practice_quistion==*/

.quis_change a {
  /*font-size: 13px;*/
  line-height: 20px;
  text-decoration: none;
  color: #FBFAFC !important;
  padding: 14px 27px 14px;
  border-radius: 4px;
  cursor: pointer;
  background-color: #613eb7;
  margin: 50px 0 0;
  display: inline-block;
}

.submit__a a {
  /*font-size: 14px;*/
  line-height: 20px;
  color: #FBFAFC !important;
  text-decoration: none;
  border-radius: 4px;
  background-color: #613eb7;
  padding: 14px 59px;
  cursor: pointer;
  display: inline-block;
  margin: 50px 20px -5px
}

.pdng {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
  height: 100%;
  padding: 0 33px 0 0;
}

.exit_btn a {
  display: inline-block;
  text-decoration: none;
  font-size: 14px;
  line-height: 20px;
  color: #FBFAFC;
  padding: 14px 61px;
  border-radius: 4px;
  margin-left: 15px;
  background-color: #000000;
}



/*==result_progress==*/

.progress {
  background-color: #EBEAED;
  border-radius: 4px;
  position: relative;
  margin: 0;
  height: 24px;
  width: 100%;
}

.progress-done {
  background: #F39D00;
  border-radius: 4px 0 0 4px;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 0;
  opacity: 1;
  transition: 1s ease 0.3s;
}

.date_p {
  font-size: 14px;
  line-height: 20px;
  margin: 0;
  color: #84818A;
}

.lear_2 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  padding: 0 20px 0 0;
}



/*==account.html===*/

.account__area {
  padding: 100px 0 134px;
}

.account__box {
  max-width: 662px;
  margin: 0 auto;
  height: 100vh;
}

.pass__box {
  max-width: 662px;
  margin: 0 auto;
  padding: 1rem;
  height: 100vh;
}

.pass_frm {
  margin-top: 31px;
}

.clr_bg {
  padding: 37px 25px 18px;
  background: #FFFFFF;
}

.edit__account {
  padding: 0 3% 0 0;
}

.edit__account > p {
  font-size: 18px;
  line-height: 24px;
  color: #2E2C34;
  font-weight: 600;
  margin: 0 0 36px;
}

.upload_btn button {
  font-size: 14px;
  line-height: 20px;
}

.upload_btn button:first-child {
  color: #613EB7;
  border-radius: 4px;
  border: 1px solid #613eb7;
  padding: 12px 28px;
  background: transparent;
  margin: 20px 0 0;
}

.upload_btn button:last-of-type {
  color: #84818A;
  background: transparent;
  border: none;
  margin: 21px 0 0;
}

.refound_profile {
  margin: 20px 0 0;
}

.question_image {
  max-height: 10rem;
  max-width: 8rem;
}

.option_image {
  max-height: 4rem;
  max-width: 4rem;
}

.refound_profile hr {
  margin: 20px -25px 20px -25px;
}

.clr_bg2 {
  padding: 4px 0 0;
}

.clr_bg2 a {
  font-size: 14px;
  line-height: 20px;
  margin-right: 0.5rem;
  display: inline-block;
  text-decoration: none;
  cursor: pointer;
  float: right;
}

.refund_a {
  color: #FFFFFF;
  padding: 13px 28px;
  background: #F20000;
  border-radius: 4px;
  text-decoration: none;
}

.refund_a:hover {
  color: #FFFFFF;
}

.save_a {
  color: #FFFFFF;
  padding: 12px 16px;
  background: #613EB7;
  border-radius: 4px;
}

.save_a:hover {
  color: #FFFFFF;
}

.cancel_a {
  color: #2E2C34;
  padding: 12px 17px;
  border: 1px solid #ebeaed;
  border-radius: 4px;
  margin-left: 16px;
}

.popup_content {
  padding: 40px 0;
}

.popup_content p {
  font-size: 14px;
  line-height: 20px;
  color: #84818A;
  margin: 20px 0;
}

.popup_content button {
  font-size: 14px;
  line-height: 20px;
  background: transparent;
  color: #2E2C34;
  padding: 12px 27px;
  border: 1px solid #ebeaed;
  border-radius: 4px;
  margin-right: 16px;
}

.account__area .modal-body {
  padding: 0;
}

.account__area .modal,
.bg-area .modal {
  background: rgba(255,255,255,0.4);
}

.account__area .modal-content {
  border: none;
  border-radius: 0;
}

.loader-container {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 9999;
    display: flex;
    justify-content: center;
    align-items: center;
}

.loader {
    border: 6px solid #f3f3f3;
    border-top: 6px solid #613EB7;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    animation: spin 2s linear infinite;
}

/* Animation for spinning */
@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}


@media screen and (min-width: 1440px) {
	.left__side {
		padding: 243px 0 230px 5%;
	}
}

@media screen and (min-width: 992px) and (max-width: 1199px) {

	/*result__details*/

	.quistion_area h3 {
		font-size: 18px;
	}

	.quistion_area ul li .check::before {
		width: 10px;
		height: 10px;
	}

	.quistion_area ul li .check {
		width: 22px;
		height: 22px;
	}

	.building_im div img {
		width: 100%;
	}

	.quistion_area ul li label {
  		padding: 21px 0 21px 27px;
  		font-size: 12px;
	}

	.quistion_area {
  		padding: 33px 25px 28px 18px;
	}


}


@media screen and (min-width: 992px) and (max-width: 1439px) {


	.container {
		padding: 0 7%;
	}

	.quiz_heading h2 {
		font-size: 30px;
	}

	.quiz_p p {
		font-size: 14px;
		padding: 0 0 0 5%;
	}

	.quiz_link {
		text-align: end;
	}

	.quiz_link a {
		font-size: 12px;
		width: 47%;
	}

	.left__side {
  		padding: 237px 32px 230px;
	}


}



@media screen and (min-width: 768px) and (max-width: 991px) {

	.container {
		padding: 0 20px;
	}

	.quiz_link a:first-child {
		margin-right: 10px;
	}

	.quiz_heading h2 {
		font-size: 30px;
	}

	.quiz_p p {
		font-size: 13px;
	}

	.quiz_link {
		text-align: end;
	}

	.quiz_link a {
		font-size: 12px;
		width: 40%;
		margin-left: 0 !important;
	}

	.result-btns {
   display: block;
	}

	.result-btns a {
    width: 40% !important;
    margin-bottom: 15px;
	}

	.sign_form {
	  	max-width: 385px;
	  	padding: 0 5%;
	}

	.around_im img {
		padding-bottom: 29%;
	}

	.left__side > h2 {
		margin: 0 0 51px 20px;
	}

	.lear_2 {
		padding: 0 0 0 40px;
	}


	.profile_im {
		text-align: center;
		margin-top: 80px;
	}



}



@media screen and (max-width: 767px) {


	.container {
		padding: 0;
		max-width: 100%;
	}

	.dmv__header {
  		padding: 40px 20px 10px;
	}

	.dmt_test .dmv__header {
		position: initial;
	}

	.dmv_logo a img {
		margin: 0;
	}

	.item_flex {
		display: flex;
		flex-direction: column;
		justify-content: center;
	}

	.s_button {
  		background: #F9F9F9;
	}

	/*.dmv_button a:first-child {*/
	/*	display: none;*/
	/*}*/

	.join_btn {
		display: none;
	}

	.hr_h {
		display: none;
	}

	.bac__bg {
		background: url('../../static/images_frontend/bg2.png') no-repeat;
		padding: 60px 20px;
  		margin: 0 0 15px;
		background-size: cover;
	}

	.bac__bg h2 br {
		display: none;
	}

	.bac__bg h2 .d-none {
		display: block !important;
	}

	.bac__bg img {
	  	top: 17%;
	  	width: 60px;
	}

	.quiz_link a {
		width: 164px;
	}

	.result-btns {
   display: block;
	}

	.result-btns a {
    width: 164px !important;
    margin-bottom: 15px;
		margin-right: 10px;
	}

	.quiz_p p {
		margin: 15px 0 20px;
	}

	.bg-area {
  		padding: 0 0 70px;
		min-height: 100vh;
	}


	/*===signin===*/

	.around_im img {
		margin: 28px 0 0 0;
	}

	.hr-block {
		display: block !important;
	}

	.aside__part {
  		padding: 34px 17px 85px;
  		margin: -25px 0 0 0;
	}


	/*create_account*/

	.sm_part {
		margin: 20px 0px 0 !important;
	}

	.bac_f9 {
		background: #F9F9F9 !important;
	}

	.left__side {
  		padding: 33px 0 50px;
	}

	.sign_form h2 {
		font-size: 34px;
	}


	/*join_quiz*/

	.se_arw span {
		display: none;
	}

	.se_arw {
  		margin-left: 13px;
	}

	.arw {
		display: none;
	}

	.main__center {
	  	padding: 95px 0 230px;
	  	margin: 0 18px;
	}

	.join_quiz {
	  	padding: 0;
	}

	.ht {
		display: block !important;
	}



	/*==result_detail==*/

	.result__details {
	  	padding: 20px 0 40px;
	  	margin: 0 18px;
	}

	.quistion_area {
	  	padding: 33px 25px 28px 28px;
	}


	/*==practice_quistion==*/

	.pdng {
		padding: 0;
	}


	.pdng .d-none {
		display: block !important;
	}

	.pdng .image_none {
		display: none;
	}

	.btn_1st {
		display: none;
	}

	.btn_2nd {
		display: block !important;
		margin: 192px 0 148px;
		text-align: start !important;
	}

	.lear_2 {
		padding: 36px 0 0;
	}

	.attempt {
		padding: 0 0 0 20px;
	}


	/*account.html*/

	.account__area {
		margin: 0 18px;
		padding: 37px 0 208px;
	}


	.profile_im {
		display: none;
	}

	.clr_bg2 .col-7 {
		padding: 0;
	}

	.man_profile {
		display: block!important;
	}

	.clr_bg {
  		padding: 37px 17px 18px;
	}

	.cancel_a {
		margin-left: 14px;
	}

	.refound_profile hr {
		display: block !important;
	}

	.account__area .modal-body {
		margin: 0 20px;
	}






}


@media screen and (max-width: 427px) {

	.dmv_button a {
	  	margin-left: 16px;
	}

	.quiz_link a {
  		width: 157px;
	}

	.result-btns a {
    width: 157px !important;
	}

	.join_quiz {
	  	padding: 0 17px;
	}

	.quistion_area {
  		padding: 33px 25px 28px 18px;
	}

	.submit__a a {
		font-size: small;
		padding: 14px 45px;
	}

	.quis_change a {
		font-size: small;
		padding: 14px 16px;
	}

	.clr_bg2 a {
		font-size: 11px;
	}

	.refund_a {
		padding: 13px 25px;
	}

	.cancel_a {
  		margin-left: 4px;
	}
}
